// Styles for Guide.
// -----------------------------------------------------------------------------

body {
  padding: 0px;
  margin: 0px;
}

#station-grid {
  font-family: 'GT-Walsheim-Pro-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0px 1px;

  tbody {
    border-top: 0;
  }

  th {
    word-wrap: break-word;
    width: 200px;
  }
  tr {
    vertical-align: top;
    height: 9px;
  }
  td {
    border: 1px solid gray;
  }

  .schedule-corner {
    border: none;
    width: 50px;
    height: 40px;
  }
  .time {
    border: 3px solid $white;
    background: $brand-primary;
    color: $white;
    width: 50px;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
  }
  .day {
    border: 3px solid $white;
    background: $brand-primary;
    padding: 5px 5px;
    color: white;
    font-size: 14px;
    padding-bottom: 5px;
    text-align: left;
    font-weight: initial;
  }
  .program {
    border: 3px solid $white;
    background: $gray-lightest;
    color: $gray-lightest;
    padding: 8px 5px 20px 5px;
    word-wrap: break-word;
    text-align: left;
    font-size: 1px;
    &:hover, &:focus {
      background: $gray-lighter;
      color: $gray-lightest;
    }

    a {
      color: $black;
      font-family: 'GT-Walsheim-Pro-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      text-decoration: none;
      border-bottom: none;
      .program-name {
        font-size: $font-size-base;
        line-height: 1.4em;
        color: $brand-primary;
        font-weight: bold;
      }
    }

    .program-broadcasters {
      font-style: normal;
      font-family: "warnockpro-regular", "Times New Roman", Times, serif;
      font-size: 13px;
      color: $color-red;
      display: block;
    }
  }
  .currentlyRunning {
    background: $color-red;
    color: $color-red;
    &:hover, &:focus {
      background: tint($color-red, 15);
      color: tint($color-red, 15);
    }

    .program-broadcasters {
      color: $white;
    }
  }
  .program-desc {
    font-style: normal;
    font-size: 11px;

  }
}

#programPageLink {
  margin-bottom: 10px;
  font-family: arial;
  font-size: 14px;
}

.poweredByLine,
.poweredByLine a {
  color: $gray-light;
  font-family: Arial, Georgia;
  font-size: 12px;
  font-style: italic;
  text-decoration: none;
}

.poweredByLine a {
  /* text-decoration: underline;*/
}

