// Typography
// -----------------------------------------------------------------------------

@include font-face('GT-Walsheim-Pro-Light', $theme-font-path);
@include font-face('GT-Walsheim-Pro-Bold', $theme-font-path);

@include font-face('warnockpro-regular', $theme-font-path);
@include font-face('warnockpro-semibold', $theme-font-path);
@include font-face('warnockpro-semiboldit', $theme-font-path);
@include font-face('warnockpro-bold', $theme-font-path);
