// Styles for News
// -----------------------------------------------------------------------------
.page-news {
  h1.title {
    text-align: center;
  }
}

.node-type-news {
  .news-title {
    background: $color-red;
    @include angle(before, true);
  }
  .background-image .news-crumbs {
    background: $color-red;
    margin-top: -2px;
    a {
      color: $white;
      &:hover, &:focus, &.active {
        color: $color-red;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid tint($color-red, 50);
    }
    padding-left: 20px;
    @include breakpoint-max(sm) {
      margin: 50px 0 0 0;

    }
  }
  @include breakpoint-max(sm) {
    .nav-tabs {
      margin: 0 !important;
    }
  }
  .news-middle {
    margin-top: $padding-large-vertical * 2;
  }
  .page-header {
    margin: 0;
  }

  .background-image .page-header {
    .background-image-cont {
      width: 100%;
      height: 500px;
      img {
        object-fit: cover;
        height: none;
        width: 100%;
      }
    }
    .container {
      position: initial;
      bottom: 0;
      height: none;
      margin-top: -$padding-large-vertical * 1.5;
      @include breakpoint-max(sm) {
        margin-top: -$padding-large-vertical * .2;
      }
      h1 {
        background: $color-red;
        display: inline-block;
        padding: 20px 20px 20px 5px;
      }
    }
  }
}


.node-news {

  .field-name-field-news-date {
    padding-bottom: 10px;
    font-size: $font-size-small;
    color: $gray;
    clear: both;
  }

  .field-name-field-news-category {
    float: left;
    font-family: 'warnockpro-regular';
    text-transform: uppercase;
    padding: $padding-small-vertical 0;
    text-decoration: none;
  }

  .field-name-field-news-alert {
    float: right;
    font-family: 'warnockpro-regular';
    text-transform: uppercase;
    background: $color-red;
    padding: $padding-base-vertical $padding-base-horizontal $padding-small-vertical $padding-base-horizontal;
    color: $white;
    font-size: $font-size-small;
    line-height: $font-size-small;
    @include rounded(20px, top-left);
    @include rounded(20px, bottom-left);
  }

  .field-name-field-reference-genre {
    display: inline-block;
    font-family: "warnockpro-semiboldit";
    font-size: $font-size-small;
    a {
      color: $brand-primary;
      &:hover, &:focus {
        color: tint($color-red, 66%);
      }
    }
    div {
      display: inline-block;
      background: url('/sites/all/themes/custom/pbs/assets/images/pbs_dot_red_tint.png') no-repeat 0px 3px;
      background-size: 10px 10px;
      padding-left: 16px;
      margin-right: 16px;
    }
  }

  .field-body {
    clear: both;
    padding-right: 15px;
  }

  //
  // Teaser display mode.
  //
  &.view-mode-teaser {
    .row {
      border: 3px solid $color-red;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      @include breakpoint-max(sm) {
        margin-left: -15px;
        margin-right: -15px;
      }
      margin-bottom: ($padding-base-horizontal * 2);
      .col-sm-3 {
        padding: 0;
      }
    }
    .field-title {
      a {
        color: $black;
        @include link_underline();
      }
    }
    .field-name-field-news-date {
      padding-bottom: $padding-xs-vertical;
    }
    .field-body {
      padding-top: $padding-small-vertical;
    }
    .field-name-field-news-alert {
      margin-right: -15px;
    }
  }

  //
  // Card display mode.
  //
  &.view-mode-card {
    height: auto;
    padding-bottom: 150px;
    @include breakpoint-max(sm) {
      padding-bottom: 95px;
    }
    margin-bottom: $padding-base-vertical * 3;
    width: 100%;
    overflow: hidden;
    .col-sm-12 {

    }
    .field-program-image {
      padding-left: 15px;
      padding-right: 15px;
    }
    .field-name-field-news-alert-cont {
      position: absolute;
      width: 100%;

      .field-name-field-news-alert {
        position: relative;
        top: -35px;
        right: 30px;
        @include breakpoint-max(md) {
          top: -60px;
        }
        @include breakpoint-max(sm) {
          right: 15px;
          top: -80px;
        }
        font-size: $font-size-xsmall;
        line-height: $font-size-xsmall;
        @include rounded(20px, top-left);
        @include rounded(20px, bottom-left);
      }
    }
    .group-more-info {
      @include angle(before);
      @include transition(all, 0.5s, ease);
      background: $gray-lightest;
      width: 100%;
      position: absolute;
      top: 100%;
      height: 800px;
      padding-left: $padding-base-horizontal * 1;
      padding-right: $padding-base-horizontal * 2;
      @include breakpoint-max(sm) {
        padding-left: $padding-base-horizontal * 2;
        padding-right: $padding-base-horizontal * 2;
      }
      &:focus, &:hover {
        background: $color-red;
      }
      color: $brand-primary;
      .field-title {
        h3 {
          color: $brand-primary;
          margin-top: 0;
          margin-bottom: $padding-base-vertical;
          padding-right: $padding-base-horizontal;
        }
      }
      .field-body {
        color: $gray-lightest;
        @include transition(all, 0.5s, ease);
        line-height: 1.2em;
      }
    }
    .field-fade {
      position: relative;
      .fade {
        position: absolute;
        width: 100%;
        height: 10px;
        background: $color-red;
        z-index: 100000;
      }
    }
    .field-name-field-news-category {
      float: none;
      font-size: $font-size-small;
      color: $black;
      font-family: "warnockpro-semibold";
    }
    .field-name-field-news-date {
      color: $color-red;
    }
    &:hover, &:focus {
      .group-more-info {
        background: $color-red;
        @include transition(all, 0.5s, ease);
        top: 25%;
        @include breakpoint-max(md) {
          top: 40%;
        }
        @include breakpoint-max(sm) {
          top: 30%;
        }
        .field-body {
          display: block;
          color: tint($color-red, 90);
          line-height: 1.1em;
          @include transition(all, 0.5s, ease);
        }
      }
      .field-name-field-news-date {
        color: $white;
      }
    }
    @include breakpoint-max(md) {
      .group-more-info {
        top: 90%;
        .field-body {
          display: none;
          color: tint($color-red, 90);
          line-height: 1.1em;
        }
      }
    }
  }

  //
  // Slideshow display mode.
  //
  &.view-mode-slideshow {

    .field-title {
      clear: both;
      h2 {
        margin-top: 15px;
        margin-bottom: 0px;
        a {
          color: $white;
        }
      }
    }
    .field-news-category {
      a {
        color: $white;
      }
    }

    .group-slideshow-container {
      height: 1px;
      position: relative;
      .group-slideshow-details {
        position: absolute;
        bottom: -20px;
        background: $color-red;
        left: 0px;
        @include breakpoint-max(sm) {
          bottom: -60px;
        }
      }
    }

    .field-name-field-news-category {
      margin-top: $padding-small-vertical;
      a {
        color: $white;
        &:hover, &:focus {
          color: tint($color-red, 80);
        }
      }
    }

    .field-name-field-news-alert {
      background: $white;
      color: $color-red;
      margin-right: -15px;
      margin-top: $padding-small-vertical;
    }

    .field-name-field-news-date {
      padding-bottom: 15px;
      font-size: $font-size-small;
      color: tint($color-red, 80);
      clear: both;
    }

    .row {
      height: 500px;
      background: none;
      .field-background-image {
        width: 100%;
        height: 500px;
        img {
          object-fit: cover;
          height: 500px;
          width: 100%;
        }
      }
      @include breakpoint-max(md) {
        height: 400px;
        .field-background-image {
          height: 400px !important;
          img {
            height: 400px;
          }
        }
      }
      @include breakpoint-max(sm) {
        height: 300px;
        .field-background-image {
          height: 300px !important;
          img {
            height: 240px;
          }
        }
      }
    }
  }

  //
  // Full Page display mode.
  //
  &.view-mode-full {
    padding-bottom: $padding-large-vertical * 3;

    .field-name-body {
      clear: both;
    }
    h3.field-label {
      display: none;
    }
    .field-news-image {
    }
  }
  .ds-section-related {
    margin: ($padding-large-vertical * 2) 0 0 0;
  }
}

.background-image .view-mode-full {
  .news-top {
    .field-name-field-news-date {
      padding-bottom: 10px;
      font-size: $font-size-small;
      color: tint($color-red, 80);
      clear: both;
    }

    .field-name-field-news-category {
      a {
        color: $white;
        &:hover, &:focus {
          color: tint($color-red, 80);
        }
      }
    }

    .field-name-field-news-alert {
      background: $white;
      color: $color-red;
      margin-right: -20px;
    }

    background: $color-red;
    margin-top: -2px;
    padding-left: 20px;
    padding-top: 5px;
    @include angle(after);
  }
}

.block-news-menu,
.field-news-categories-block {
  h4 {
    font-size: $font-size-h2;
  }
  @include extend_bg_left(tint($color-blue, 88%), 9999px);
  @include extend_bg_right(tint($color-blue, 88%), 9999px);
  background: tint($color-blue, 88%);
  padding: ($padding-large-vertical * 3) 0;
  position: relative;
  .row-fluid > div {
    padding: 10px 0;
  }
  p {
    font-size: $font-size-h3;
    color: $color-red;
  }
  a {
    font-size: $font-size-h4;
    font-family: "GT-Walsheim-Pro-Bold";
    @include link_style();
  }
}

.field-program-related-news {
  margin-top: $padding-large-vertical * 3;
}

.field-news-category-image {
  width: 100%;
  display: block;
  img {
    margin: 0 auto;
  }
}

#draggableviews-table-news-order {
  tbody {
    tr:nth-child(12) {
      border-bottom: 5px solid $black;
    }
  }
}

.page-taxonomy-term-45 {
  .view-taxonomy-news {
    h2.block-title {
      display: none;
    }
  }
  .field-name-field-news-date {
    display: none;
  }
}
