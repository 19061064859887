// Styles for Views
// -----------------------------------------------------------------------------

#content {
  .view {
    margin-top: $padding-large-vertical * 2;
    .view-header {
      margin-bottom: $padding-large-vertical * 2;
      .taxonomy-term-description {
        font-size: $font-size-large;
      }
    }
  }
}
