// Styles for Search.
// -----------------------------------------------------------------------------

ol.search-results {
  padding-left: 0;

  .search-snippet-info {
    padding-left: 0;
  }
}

.search-results {
  h3.title a {
    font-size: $font-size-h4;
    @include link_style();
  }
  p.search-snippet {
    font-size: $font-size-base;
  }
}

.block--search-results {
  margin: 33px 0 ($padding-base-vertical * 4) 0;
  h4.block__title {
    display: none;
  }
}
