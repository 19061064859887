// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------

h1 {
  @include breakpoint-max(sm) {
    font-size: $font-size-h2;
  }
}

h3.label {
  @include element_h3_label();
}

h4.block__title {
  @include element_h4_label();
}

.row.is-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
  background: $gray-lightest;
  border-left: 10px solid $white;
  border-right: 10px solid $white;
  margin-bottom: $padding-large-vertical;
  @include breakpoint-max(sm) {
    border: none;
  }
}

.views-responsive-grid .row > [class*='col-'] {
  @include breakpoint-max(sm) {
    padding: 0;
  }
}

.is-flex .box {
  background: none;
  position: static;
}

//
// Pagination Color overrides.
//
.pagination > li > a, .pagination > li > span {
  color: $white;
  background-color: $pagination-bg;
}

.pagination > li > a:hover, .pagination > li > a:focus{
  color: $white;
  background: $color-red;
}
.pagination > li > span:hover, .pagination > li > span:focus {
  background: lighten($brand-primary, 25%);
  color: $white;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus {
  color: $white;
  background: $color-red;
  border: none;
}
.pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background: lighten($brand-primary, 25%);
  color: $white;
}

.pager--infinite-scroll {
  &.pager li > a {
    background-color: $color-red;
    border: none;
    &:hover, &:focus {
      background: $brand-primary;
      color: $white;
    }
  }
}


.ajax-pages-loading .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  /* center the element */
  position: fixed;
  z-index: 9999;
  top:40%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.more-link {
  text-align: center;
  margin-bottom: $padding-base-vertical * 3;
  font-family: "warnockpro-semibold";
  text-transform: uppercase;
  font-size: $font-size-xsmall;
}

.stripe {
  margin: ($padding-base-vertical * 8) 0;
  h4.block__title {
    font-size: $font-size-h2;
    background: none;
    padding: 0;
    margin-top: 0;
  }

  @include extend_bg_left($brand-primary, 9999px);
  @include extend_bg_right($brand-primary, 9999px);
  background: $brand-primary;
  padding: ($padding-large-vertical * 3) 0;
  position: relative;

}


/*
 * Bootstrap Custom Columns.
 */
.col-xs-1-5,
.col-sm-1-5 {
  position: relative;
  min-height: 1px;
}

.col-xs-1-5 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }
}

.col-xs-1-10,
.col-sm-1-10 {
  position: relative;
  min-height: 1px;
}

.col-xs-1-10 {
  width: 10%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-10 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-10 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-10 {
    width: 10%;
    float: left;
  }
}

.has-error.checkbox label {
  color: unset;
}
