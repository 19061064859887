// Styles for Nodes
// -----------------------------------------------------------------------------

.node {

  padding-bottom: $padding-base-vertical * 3;

  .content {

    #slider {
      &::before {
        content: "Gallery";
        @include element_h4_label();
        line-height: 2.5;
      }
      padding-top: $padding-base-vertical * 2;
    }

  }

  &.node-program {
    padding-bottom: 0;
  }

}

