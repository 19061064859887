
.node-member {
  &.node-teaser {
    h4 {

    }
    .field-group-link {
      .ext {
        display: none;
      }
      h4 {
        color: $brand-primary;
        border-bottom: 2px solid $color-blue;
        display: inline;
        line-height: 3rem;

        &.hover, &.focus {
          color: $color-red;
          border-bottom: 2px solid $color-red;
        }
      }
    }
  }
}

