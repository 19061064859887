// Typography
// -----------------------------------------------------------------------------
$theme-font-path: 'https://www.pbsfm.org.au/sites/all/themes/custom/pbs/assets/fonts';
//$theme-font-path: 'http://local.pbsfm.org.au/sites/all/themes/custom/pbs/assets/fonts';

@include font-face('GT-Walsheim-Pro-Bold', $theme-font-path);

@include font-face('GT-Walsheim-Pro-Light', $theme-font-path);
@include font-face('GT-Walsheim-Pro-Bold', $theme-font-path);

@include font-face('warnockpro-regular', $theme-font-path);
@include font-face('warnockpro-semibold', $theme-font-path);
@include font-face('warnockpro-semiboldit', $theme-font-path);
@include font-face('warnockpro-bold', $theme-font-path);
