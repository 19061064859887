// Mixins
// -----------------------------------------------------------------------------

//
// Add font-face for custom fonts.
//
@mixin font-face($name, $path, $style: normal, $weight: normal) {
  @font-face {
    font-family: $name;
    src: url('#{$path}/#{$name}/#{$name}.eot'); // IE9 compat
    src: url('#{$path}/#{$name}/#{$name}.eot?#iefix') format('embedded-opentype'), // IE8 and below
    url('#{$path}/#{$name}/#{$name}.woff') format('woff'), // standards
    url('#{$path}/#{$name}/#{$name}.ttf') format('truetype'), // Safari, Android, iOS
    url('#{$path}/#{$name}/#{$name}.svg##{$name}') format('svg'); // legacy iOS
    font-style: $style;
    font-weight: $weight;
  }
}

//
// Breakpoint.
//
@mixin breakpoint($name) {
  @if $name==xs {
    @media (min-width: $screen-xs) {
      @content;
    }
  } @else if $name==sm {
    @media (min-width: $screen-sm) {
      @content;
    }
  } @else if $name==md {
    @media (min-width: $screen-md) {
      @content;
    }
  } @else if $name==lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

//
// Breakpoint for max width.
//
@mixin breakpoint-max($name) {
  @if $name==sm {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $name==md {
    @media (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $name==lg {
    @media (max-width: $screen-md-max) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: sm, md, lg";
  }
}

//
// Mixin vertical align.
//
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//
// Mixin Responsive Image.
//
@mixin responsive-image() {
  display: block;
  max-width: 100%;
  height: auto;
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

//
// Mixin Rounded Corners.
//
@mixin rounded($amount: "10px",$position: null) {
  @if $position != null {
    @if $position == "top" or $position == "bottom" {
      //top or bottom
      -webkit-border-#{$position}-left-radius: $amount;
      -moz-border-#{$position}-left-radius: $amount;
      border-#{$position}-left-radius: $amount;

      -webkit-border-#{$position}-right-radius: $amount;
      -moz-border-#{$position}-right-radius: $amount;
      border-#{$position}-right-radius: $amount;

    } @else {
      // top-left or top-right or bottom-left or bottom-right
      -webkit-border-#{$position}-radius: $amount;
      -moz-border-#{$position}-radius: $amount;
      border-#{$position}-radius: $amount;
    }
  } @else {
    // ALL IF EMPTY
    -webkit-border-radius: $amount;
    -moz-border-radius: $amount;
    border-radius: $amount;
  }
}

//
// Extend the background beyond the div to the left.
//
@mixin extend_bg_left($background_color, $width) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: $width;
    right: 100%;
    background: $background_color;
    z-index: -1;
  }
}

//
// Extend the background beyond the div to the right.
//
@mixin extend_bg_right($background_color, $width) {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$width;
    right: 0;
    box-shadow: $width 0 0 $background_color;
    z-index: -1;
  }
}

//
// Link Style.
//
@mixin link_style() {
  color: $black;
  border-bottom: 2px solid $color-blue;
  &:hover, &:focus {
    color: $color-red;
    border-bottom: 2px solid $color-red;
  }
}

//
// Link Underline Style.
//
@mixin link_underline($color: $color-red) {
  &:hover, &:focus {
    color: $color;
  }
}

//
// Element H3 Label Style.
//
@mixin element_h3_label() {
  text-transform: uppercase;
  font-size: $font-size-small;
  font-family: "GT-Walsheim-Pro-Light";
  font-weight: 500;
  padding: 0;
}

//
// Element H3 Style.
//
@mixin element_h3() {
  font-size: $font-size-h3;
  font-family: "GT-Walsheim-Pro-Bold",Helvetica,Arial,sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

//
// Element H4 Label Style.
//
@mixin element_h4_label() {
  background: $black;
  color: $white;
  margin-bottom: ($padding-large-vertical * 1);
  padding: 10px 20px;
  text-transform: uppercase;
  @include breakpoint-max(sm) {
    margin-left: -15px;
    margin-right: -15px;
  }
  font-size: $font-size-h3;
  font-family: "GT-Walsheim-Pro-Bold",Helvetica,Arial,sans-serif;
  font-weight: 500;
  line-height: 1.1;
  display: inline-block;
  @include angle(before, true);
  @include angle(after);
}

//
// Transition Mixin.
//
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition:    $what $time $how;
  -ms-transition:     $what $time $how;
  -o-transition:      $what $time $how;
  transition:         $what $time $how;
}

//
// Slanted border.
//
@mixin angle($pseudo, $flip: false, $angle: 0.8deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 60%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

// slanted edges here
.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}

.-berry {
  background: #b52b4a;
}

.-blue {
  background: #41ade5
}

.-orange {
  background: #de6628;
}

.-green {
  background: #5e9b42;
}


//
// Heading Stripe Style.
//
@mixin heading_stripe($color: $color-red) {
  font-size: $font-size-h4;
  text-transform: uppercase;
  @include angle(before, true);
  @include angle(after);
  background: $black;
  padding: 10px 15px;
  margin-bottom: 20px;
  color: $white;
  display: inline-block;
}

/*
 * Mixin for Linear Gradient.
 */

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}
