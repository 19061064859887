// Flexslider
// -----------------------------------------------------------------------------

.flexslider {
  background: none;
  border: none;
  margin-bottom: 20px;
  @include breakpoint-max(sm) {
    margin-bottom: 80px;
  }

  .flex-control-nav {
    z-index: 1000;
    bottom: -20px;
    @include breakpoint-max(sm) {
      bottom: -60px;
    }
  }

  .flex-direction-nav {
    a {
      height: 50px;
      &::before {
        color: $brand-primary;
      }
    }
  }
}
