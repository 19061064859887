@media (max-width: 500px) {
  #station-grid, #programPageLink { display: none; }
}
@media (min-width: 500px) {
  .mobileGuide { display: none; }
}

/* Standard mobile list buttons: */
/* Start mobile filter box: */
.filterOut.filterOut {
  display: none;
}
/* End filter box */
/* Map standard components to our UI: */
.mobileGuide ul {
  list-style: none;
  padding: 0 0;
  width: 100%;
  font-size: 10pt;
  font-family: sans-serif;
  color: #222;
}
.mobileGuide ul li {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #cccccc;
  padding: .7em 1em;
  background-image: linear-gradient(#fff, #e6e6e6);
  line-height: 1.3;
  font-weight: bold;
  text-overflow: ellipsis;
  /* TODO: TEST! */
  overflow: hidden;
}
.mobileGuide ul a,
.mobileGuide ul a:visited {
  color: #222;
  text-decoration: none;
}
.mobileGuide ul .hasLink:hover {
  cursor: pointer;
  background-image: linear-gradient(#e0e0e0, #d1d1d1);
}
.mobileGuide ul .hasLink:active {
  background-image: linear-gradient(#cccccc, #cccccc);
}
.dayHeader.dayHeader {
  color: #fff;
  background-image: none;
  background-color: #222;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #555555;
  text-shadow: 0 1px 0 #111;
  /* CHECK, maybe parameterise the colour */
  padding: 5px .7em 5px 1em;
  font-weight: normal;
}
button {
  cursor: pointer;
  text-align: center;
  background: transparent;
  font-size: 14px;
  font: sans-serif;
  line-height: 21px;
  border-radius: 5px;
  padding: 4.2px 11.9px;
  color: #fff;
  background-color: #396b9e;
  border: 1px solid #396b9e;
  outline: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.filterShows {
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #a2a2a2;
  border-radius: .3125em;
  display: flex;
  align-items: center;
}
.filterShows .fa-search {
  flex-shrink: 0;
  padding: 5px 5px 5px 5px;
  color: #a2a2a2;
}
.filterShows .fa-times-circle {
  flex-shrink: 0;
  color: #a2a2a2;
  padding-right: 8px;
}
.filterShows input {
  flex-grow: 1;
  border-style: none;
}
.filterShows input:focus {
  outline: none;
}
.filterShows.focus {
  box-shadow: 0 0 12px #396b9e;
}
/* Layout toolbar: */
.tools {
  display: flex;
}
.filterShows {
  margin-left: 10px;
  margin-right: 5px;
  flex-grow: 1;
}
.jumpToToday {
  white-space: nowrap;
  margin-left: 3px;
  flex-shrink: 0;
}
/* Customise program buttons: */
.programNameAndBroadcaster,
.programTimes {
  display: block;
}
.linkArrow {
  font-size: 20pt;
  color: #888888;
}
/* Layout of row: */
.programLine {
  display: flex;
  align-items: center;
}
.programText {
  flex-grow: 1;
}
.linkArrow {
  flex: 0 0 23px;
}
.onAirNow.onAirNow {
  background-image: linear-gradient(#5f9cc5, #396b9e);
}
li.onAirNow.onAirNow,
li.onAirNow.onAirNow a,
li.onAirNow.onAirNow a:visited {
  color: #fff;
  text-shadow: 0 1px 0 #194b7e;
}
