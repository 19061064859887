.mailchimp-signup-subscribe-form {
  .mailchimp-signup-subscribe-form-description {
    color: $white;
  }
  .form-submit {
    background-color: $color-red;
    border-color: tint($color-red,10);
    font-weight: bold;
    &:hover, &:focus {
      background-color: $black;
      border-color: $color-red;
      color: $color-red;
    }
  }
}
