// Styles for Ads.
// -----------------------------------------------------------------------------

.field-ad-image {
  text-align: center;
  margin-bottom: $padding-base-vertical * 2;
  a {
    display: inline-block;
  }
}
