// Flatly 3.0.0
// Bootswatch
// -----------------------------------------------------

// Navbar =====================================================================

// Buttons ====================================================================

.btn:active {
	@include box-shadow(none);
}

.btn-group.open .dropdown-toggle {
	@include box-shadow(none);
}

// Typography =================================================================

.text-primary {
	color: $brand-primary;
}

.text-success {
	color: $brand-success;
}

.text-danger {
	color: $brand-danger;
}

.text-warning {
	color: $brand-warning;
}

.text-info {
	color: $brand-info;
}

// Tables =====================================================================

.table {

	tr.success,
	tr.warning,
	tr.danger {
		color: #fff;
	}
}

// Forms ======================================================================

.form-control,textarea.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	border-width: 2px;
	@include box-shadow(none);

	&:focus {
		@include box-shadow(none);
	}
}

.has-warning {
	.help-block,
	.control-label {
		color: $brand-warning;
	}

	.form-control,
	.form-control:focus {
		border: 2px solid $brand-warning;
	}
}

.has-error {
	.help-block,
	.control-label {
		color: $brand-danger;
	}

	.form-control,
	.form-control:focus {
		border: 2px solid $brand-danger;
	}
}

.has-success {
	.help-block,
	.control-label {
		color: $brand-success;
	}

	.form-control,
	.form-control:focus {
		border: 2px solid $brand-success;
	}
}

// Navs =======================================================================

.pagination {

	a {
		color: #fff;

		&:hover {
			color: $text-color;
		}
	}

	.disabled {
		&>a,
		&>a:hover,
		&>a:focus,
		&>span {
			background-color: lighten($brand-success, 15%);
		}
	}
}

.pager {
	a {
		color: #fff;
		&:hover {
			color: $text-color;
		}
	}

	.disabled {
		&>a,
		&>a:hover,
		&>a:focus,
		&>span {
			background-color: lighten($brand-success, 15%);
		}
	}
}

// Indicators =================================================================

.alert {

	a,
	.alert-link {
		color: #fff;
		text-decoration: underline;
	}
}

// Progress bars ==============================================================

.progress {
	height: 10px;
	@include box-shadow(none);
}

// Containers =================================================================

.well {
	@include box-shadow(none);
	border-width: 0;
}
