// Styles for Video.
// -----------------------------------------------------------------------------

.node-video {
  //
  // Card display mode.
  //
  &.view-mode-card {
    height: auto;
    padding-bottom: 120px;
    margin-bottom: $padding-base-vertical * 3;
    width: 100%;
    overflow: hidden;
    .col-sm-12 {
      padding: 0;
    }
    .field-video {
      padding-left: 15px;
      padding-right: 15px;
    }
    .group-more-info {
      @include angle(before);
      @include transition(all, 0.5s, ease);
      background: $color-red;
      width: 100%;
      position: absolute;
      top: 100%;
      height: 800px;
      padding-left: $padding-base-horizontal * 2;
      padding-right: $padding-base-horizontal * 2;
      color: $brand-primary;
      .field-title {
        h3 {
          color: $white;
          margin-top: 0;
          margin-bottom: $padding-base-vertical;
        }
      }
    }
    .field-video-label {
      padding: 6px 0;
      span {
        float: none;
        font-size: $font-size-small;
        color: $black;
        font-family: "warnockpro-semibold";
        text-transform: uppercase;
      }
    }
    &:hover, &:focus {
      .group-more-info {
        @include transition(all, 0.3s, ease);
        top: 90%;
      }
    }
    @include breakpoint-max(md) {
      .group-more-info {
        top: 90%;
        .field-body {
          display: block;
          color: tint($color-red, 90);
          line-height: 1.1em;
        }
      }
    }
  }
}

.view-video {
  .field-video-label {
    display: none;
  }
  .field-title {
    padding: 6px 0px;
  }
}


#lightbox {
  #outerImageContainer {
    background: none !important;
  }
  #modalContainer {
    overflow: hidden;
  }
  #imageDataContainer {
    display: none !important;
  }
}
