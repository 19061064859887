// Front Page Layout
// -----------------------------------------------------------------------------

.front {
  h1.title {
    display: none;
  }

  .page-header {
    background: none;
    border-bottom: 0;
    height: auto;
    .container {
      position: unset;
      top: unset;
      -webkit-transform: unset;
      -ms-transform: unset;
      transform: unset;
    }
  }
  #header-content {
    width: 100%;
    overflow: hidden;
  }

  .block {
    text-align: center;
    .block__content {
      text-align: left;
    }
  }
  #footer {
    .block {
      text-align: left;
    }
  }
  .view-display-id-block_4 {
    margin-bottom: $padding-large-vertical * 4;
  }
}

