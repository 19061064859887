// Styles for Header
// -----------------------------------------------------------------------------

//
// Overrides for Admin Menu.
//
$navbar-lineheight: 50px;
$admin-menu-height: 30px;

#admin-menu, #admin-menu .dropdown {
  z-index: 1050;
}

.admin-menu .navbar-fixed-top {
  margin-top: 29px;
}

#main-wrapper {
  margin-top: $navbar-height + $navbar-player-height;
}

.admin-menu #main-wrapper {
  margin-top: $navbar-height + $navbar-player-height + $admin-menu-height;
}

.navbar-nav > li > a {
  padding: 10px 12px;
}

@include breakpoint(sm) {
  .navbar-nav > li > a {
    line-height: $navbar-lineheight;
  }
}


.header {
  nav {
    @include transition(all, 0.4s, ease);
    padding-top: 0;
    @include breakpoint(md) {
      &.navbar-fixed-top {
        padding-top: $navbar-player-height;
      }
    }
    &.navbar-large {

      padding-top: $navbar-player-height;
    }
  }
  @include breakpoint(md) {
    .navbar-right {
      margin-right: 0;
    }
  }

  a.btn-join {
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .search-form {
    clear: both;
    margin-top: 0;
  }
  a.dropdown-search {
    padding-top: 0;
    top: 15px;
    i {
      width: 16px;
    }
  }
  .navbar-nav > .open > a.dropdown-search {
    background: none;
  }
  .search-dropdown {
    position: relative;
    form {
      position: absolute;
      right: 0;
      top: 85px;
      border-top: 0;
      @include breakpoint-max(md) {
        top: -40px;
      }
    }
  }
}

.page-header {
  background: $color-red;
  height: 130px;
  .container {
    @include vertical-align();
    h1 {
      color: $white;
    }
  }
}

.background-image .page-header {
  height: 500px;
  background: none;
  margin-bottom: 60px;
  .background-image-cont {
    width: 100%;
    height: 500px;
    img {
      object-fit: cover;
      height: inherit;
      width: 100%;
    }
  }
  .container {
    position: relative;
    bottom: 55px;
    height: 1px;
    top: inherit;
    h1 {
      background: $color-red;
      display: inline-block;
      padding: 20px;
      width: 100%;
      @include angle(before, true);
      @include angle(after);
    }
  }
  @include breakpoint-max(md) {
    height: 400px;
    .background-image-cont {
      height: 400px !important;
    }
  }
  @include breakpoint-max(sm) {
    height: 300px;
    .background-image-cont {
      height: 300px !important;
    }
    .container {
      bottom: 42px;
      h1 {
        font-size: $font-size-h1-sm;
      }
    }
  }
}

