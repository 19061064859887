// Styles for AMRAP Pages.
// -----------------------------------------------------------------------------

.amrap_program {
  h1 {
    font-size: $font-size-h2;
  }
  .amrap_program--tabs {
    div > div >div > button {
      font-size: $font-size-smaller;
      box-shadow: none;
    }
    div > button {
      font-size: $font-size-smaller;
      box-shadow: none;
    }
  }
  div.amrap_playlist--container {
    > h1, > p {
      width: 80%;
      float: left;
      @include breakpoint-max(md) {
        width: 70%;
      }
      @include breakpoint-max(sm) {
        width: 100%;
        float: none;
      }

    }
  }
  div.episode-image-container {
    width: 20%;
    float: right;
    margin-top: -40px;
    @include breakpoint-max(md) {
      width: 30%;
    }
    @include breakpoint-max(sm) {
      width: 100%;
      margin-top: 0;
      float: none;
    }
    img {
      width: 100%;
    }
  }

  .MuiInput-underline-12::after {
    border-bottom: 2px solid $color-red;
  }

  #select-dateId {
    margin-top: $padding-base-vertical;
    font-size: $font-size-h4 ;
    font-weight: bold;
    color: $color-red;
    margin-bottom: 5px;
    &::before {
      content: 'EPISODE';
      color: $brand-primary;
      font-size: $font-size-xsmall;
      display: block;
      font-weight: 100;
      padding-bottom: 5px;
    }
    
  }




  .amrap_playlist {

    // Track Time
    .amrap_playlist--track-time {
      vertical-align:top;
      @include breakpoint-max(sm) {
        display: inline-block;
        width: 18% !important;
      }
    }

    // Artist Details
    .amrap_playlist--artist {
      vertical-align:top;
      @include breakpoint-max(sm) {
        display: inline-block;
      }
    }

    // Player
    .amrap_playlist--player {
      @include breakpoint-max(sm) {
        display: inline-block;
        width: 7% !important;
        text-align: right;
      }
      .mini-player .mini-player {
        color: $color-red;


        &:hover, &focus {
          color: $black;
        }
        @include breakpoint-max(sm) {
          font-size: 1px;
          float: right;
          svg {
            font-size: 30px;
          }
        }
      }
    }

    .amrap_playlist--cell {
      font-size: $font-size-small;
      padding: 4px;
      @include breakpoint-max(sm) {
        font-size: $font-size-xsmall;
        vertical-align:top;
      }

      .cell-inner {
        padding: 0px !important;
        strong {
          font-family: "GT-Walsheim-Pro-Bold", Helvetica, Arial, sans-serif;
        }
      }
    }
  }

  // Info
  .amrap_playlist--info {
    @include breakpoint-max(sm) {
      width: 100% !important;
    }
  }
}

















#page {

  a {
    color: $black;
    &:hover, &:focus {
      color: $color-red;
    }
  }
  .archive, .latest {
    a {
      color: $gray-light;
      text-transform: uppercase;
      font-size: $font-size-xsmall;
      &:hover, &:focus {
        color: $color-red;
      }
    }
  }
  .programTime-main {
    font-size: $font-size-h2;
    font-family: "GT-Walsheim-Pro-Bold",Helvetica,Arial,sans-serif;
    font-weight: bold;
    line-height: 1.1;
  }
  #applyForPage {
    display: none;
  }
  .static p {
    font-family: "GT-Walsheim-Pro-Light",Helvetica,Arial,sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $brand-primary;
  }
  .listenerStartTime a {

  }
  .playlist-mainText a {
    font-size: $font-size-base;
    font-weight: bold;
  }
  .trackContent a {
    padding-right: 5px;
    color: $color-red;
    text-decoration: none;
    font-size: $font-size-xsmall;
    &:hover, &:focus {
      color: $black;
    }
  }
  .highlightRow {
    background-color: $brand-primary;
    .listenerStartTime a {
      color: $white;
    }
    .playlist-mainText a {
      font-size: $font-size-base;
      font-weight: bold;
      color: $white;
    }
    .trackContent a {
      &:hover, &:focus {
        color: $white;
      }
    }
  }
  #searchLink {
    padding-right: 10px;
    color: $color-red;
    text-transform: uppercase;
    text-decoration: none;
    font-size: $font-size-xsmall;
    &:hover, &:focus {
      color: $brand-primary;
    }
  }
  #contentPane {
    padding-bottom: 0;
    .trackBlock {
      padding: 10px 0;
    }
  }
  #calendarStrip {
    .viewport {
      height: 40px;
      .backwards {

      }
      .episode {
        .episodeDay {
          background: $brand-primary;
          font-family: 'GT-Walsheim-Pro-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          height: 26px;
          line-height: 26px;
          color: $white;
        }
        &.currentlyRunning {
          .episodeDay {
            background: $color-red;
            color: $white;
          }
        }
        a.changeEpisode {
          margin-right: 2px;
        }
      }
    }
    .month {
      color: $brand-primary;
    }
    .inactiveButton {
      background: $color-red;
      border: 1px solid $white;
      box-sizing: border-box;
      height: 26px;
      line-height: 26px;
      .fa {
        color: $white;
      }
    }
  }
  .firstAired {
    display: none;
  }
  .shareLink {
    font-size: .1px;
    color: $white;
  }

  #playlistColumn {
    .trackListPane {
      table {
        .playlist-track {
          td {
            border: 0;
          }
        }
      }
    }
  }
}


.MuiMenuItem-root-84 {
  height: 20px;
  font-size: 1.4rem;
}
