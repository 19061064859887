// Footer Styles.
// -----------------------------------------------------------------------------

#footer {
  margin-top: 0;
  margin-bottom: 0;
  padding: 30px 0;
  background: $brand-primary;
  @include angle(before, true);
  #footer-brand {
    padding: $padding-large-vertical 0;
    .col {
      height: 72px;
      .footer-slogan-cont {
        font-family: $font-family-serif;
        color: $gray-75;
        @include vertical-align();
      }
    }
    img {
      max-height: 70px;
    }
  }
  p {
    color: $gray-75;
  }
  hr {
    margin: $padding-large-vertical 0;
    border: 1px solid $gray-10;
  }

  .acknowledgement {
    color: $gray-60;
    font-size: 0.8em;
  }

  h4.block__title {
    background: unset;
    padding: 0;
    @include breakpoint-max(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .social-icons {
    a {
      margin-right: $padding-base-horizontal;
      .fa {
        font-size: 1.6em;
        color: $white;
        &:hover, &:focus {
          color: $color-red;
        }
      }
    }
  }
}
