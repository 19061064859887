// Styles for Interviews
// -----------------------------------------------------------------------------

.node-interview {
  //
  // Card display mode.
  //
  &.view-mode-card {
    height: auto;
    padding-bottom: 180px;
    margin-bottom: $padding-base-vertical * 3;
    width: 100%;
    overflow: hidden;
    .col-sm-12 {
      padding: 0;
    }
    .field-interview-image {
      padding-left: 15px;
      padding-right: 15px;
    }
    .group-more-info {
      @include angle(before);
      @include transition(all, 0.5s, ease);
      background: $gray-darker;
      width: 100%;
      position: absolute;
      top: 100%;
      height: 800px;
      padding-left: $padding-base-horizontal * 2;
      padding-right: $padding-base-horizontal * 2;
      color: $brand-primary;
      .field-title {
        h3 {
          color: $white;
          margin-top: 0;
          margin-bottom: $padding-base-vertical;
        }
      }
      .field-interview-reason {
        color: $gray-5;
        color: tint($gray-dark, 70);
        @include transition(all, 0.3s, ease);
        display: none;
      }
    }
    .field-interview-type {
      padding: 6px 0;
      div {
        display: none;
      }
      span {
        float: none;
        font-size: $font-size-small;
        color: tint($gray-dark, 70);
        font-family: "warnockpro-semibold";
        text-transform: uppercase;
      }
    }
    .field-preferred-program {
      padding: 6px 0;
      color: tint($gray-dark, 70);
      font-size: 0.9em;
      a {
        font-size: 1.1em;
        color: tint($gray-dark, 90);
        &:hover, &:focus {
          color: $color-red;
        }
      }
    }
    .field-name-field-interview-date {
      color: $color-red;
    }
    &:hover, &:focus {
      .group-more-info {
        @include transition(all, 0.3s, ease);
        top: 10%;
        .field-interview-reason {
          display: block;
          line-height: 1.1em;
          @include transition(all, 0.3s, ease);
        }
        .field-title, .field-interview-type, .field-name-field-interview-date {
          display: none;
        }
      }
    }
    @include breakpoint-max(md) {
      .group-more-info {
        top: 90%;
        .field-body {
          display: block;
          color: tint($color-red, 90);
          line-height: 1.1em;
        }
      }
    }
  }
}

.view-interviews-dashboard {
  .editablefield-item {
    .form-actions {
      //display: none;
      margin: 0;
    }
    .form-type-select,
    .field-type-text,
    .field-type-email {
      label {
        display: none;
      }
    }
  }
  .form-control {
    padding: 5px 5px;
    font-size: $font-size-small;
  }

  .field-name-field-interview-type {
    width: 90px;
  }
  .field-name-field-interview-phone {
    width: 110px;
  }
  .field-name-field-interview-status {
    width: 170px;
  }

  .views-exposed-form {
    .form-control {
      padding: 5px 5px;
    }
  }
  .views-table {
    .btn {
      padding: 2px;
      font-size: 0.6em;
    }
    .btn-default {
      background: $gray-light;
      border: none;
      &:hover {
        background: $color-red;
      }
    }
  }
}

.page-node-77903 {
  #admin-menu {
    display: none;
  }
}
