// Styles for AMRAP Player.
// -----------------------------------------------------------------------------

#persistent-audio {
  @include transition(all, 0.4s, ease);
  z-index: 1035;
  height: $navbar-player-height;
  border-bottom: 1px solid $gray-5;

  &.navbar {
    background: $gray-2;
    min-height: $navbar-player-height;
  }
  #amrapPlayer {
    height: $navbar-player-height;
  }
  @include breakpoint-max(md) {
    &.player-small {
      overflow: visible;

      &.navbar {
        background: none;
        height: 1px;
        min-height: 1px;
      }

      .container {
        height: 1px;


        #amrapPlayer {
          .button.previous,
          .button.seek-left,
          .button.seek-right,
          .button.next,
          .track-status {
            display: none;
          }

          .left-buttons {
            padding-top: 24px;
          }

          @include transition(all, 0.4s, ease);
          width: 60px;
          height: 60px;
          padding-top: 10px;
          left: 200px;
          position: relative;
          overflow: hidden;
        }
      }
    }
  }
}



#amrapPlayer {

  .amrap-player-outer {
    height: 50px;
    color: $brand-primary;
    background: none;

    .button {

    }

    .left-buttons {
      background: none;
      @include transition(all, 0.4s, ease);
      padding: 0;
      .button {
        color: $gray-20;
        &:hover, &:focus {
          color: $gray-75;
        }
        .flaticon-play, .flaticon-pause {
          color: $white;
          &:hover, &:focus {
            color: $color-red;
          }
        }
        &.loading {
          left: 41px;
          .loader-outer .loader {
            border-color: lighten($color-red, 25);
            border-left-color: $color-red;
          }
        }
        &.disabled {
          color: $gray-10;
        }
        &.seek-left,
        &.seek-right {
          display: none;
        }
      }
    }

    .track-status {
      padding: 4px 0 4px 15px;
      background: none;
      &.player-small {
        padding-top: 24px;
        @include transition(all, 0.4s, ease);
      }
      .mid {
        font-size: 12px;

        margin: 2px 0;
        .left {
          color: $gray-75;
          .show-name {
            color: $gray-30;
            font-weight: bold;
          }
          .title {
            color: $gray-60;
            font-weight: bold;
          }
        }
        .right {
          .switch-type {
            color: $color-red;
            &:hover, &:focus {
              color: $gray-75;
            }
          }
        }
      }
      .lower {
        .precise-clock, .track-time {
          font-size: 12px;
          color: $gray-30;
        }
        .bar {
          height: 10px;
          margin: 6px 0;
          background: $gray-10;
          .bar-background {
            background: $gray-75;
          }
          .bar-segment {
            background: $gray-30;
            &.playing {
              background: $color-red;
            }
          }
        }
      }
    }
  }

  .amrap-mobile-player-outer {
    height: 75px;

    & > .upper {
      background: none;
      .track-status {
        background: none;
      }
      .left-buttons {
        background: none;
        @include transition(all, 0.4s, ease);
        padding: 0;
        .button {
          color: $gray-20;
          &:hover, &:focus {
            color: $gray-75;
          }
          .flaticon-play, .flaticon-pause {
            color: $color-red;
            &:hover, &:focus {
              color: $gray-75;
            }
          }
          &.loading {
            left: 41px;
            .loader-outer .loader {
              border-color: lighten($color-red, 25);
              border-left-color: $color-red;
            }
          }
          &.disabled {
            color: $gray-10;
          }
        }
      }
    }
    .lower {
      .precise-clock, .track-time {
        font-size: 12px;
        color: $gray-30;
      }
      .bar {
        height: 10px;
        margin: 6px 0;
        background: $gray-5;
        .bar-background {
          background: $gray-75;
        }
        .bar-segment {
          background: $gray-50;
          &.playing {
            background: $color-red;
          }
        }
      }
    }
  }
}
