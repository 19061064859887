// Styles for Search.
// -----------------------------------------------------------------------------
#page {
  margin: 0 0 40px 0;
}
a.link {
  font-size: $font-size-h4;
  font-weight: bold;
  font-family: "GT-Walsheim-Pro-Bold", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.4;
  color: inherit;
  @include link_style();
  &:hover, &:focus {
    text-decoration: none;
  }
}
#programResults {
  .program, .episode {
    margin: 0;
  }
  .notes {
    width: unset;
    margin: 11px 0 33px 0;
    font-family: "GT-Walsheim-Pro-Light", Helvetica, Arial, sans-serif;
    font-size: $font-size-base;
  }
}
#playResults {
  margin-left: 0;
  font-size: unset;
  .artist {
  }
}
.tracksHeader {

}
.musicianLink {
  display: none;
}

.resultsHeader {

}

.pageNavigator {
  text-align: center;
  font-size: unset;
}
