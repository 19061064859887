// Styles for Program Page.
// -----------------------------------------------------------------------------

.node-type-program,
.page-program {
  .page-header {
    margin: 0;
  }
}

.node-program {

  //
  // Full Page Display.
  //
  &.view-mode-full {

    .field-name-program-title {
      h3 {
        margin-top: 0;
      }
    }

    .ds-section-top {
      @include angle(before, true);
      @include angle(after);
      padding: $padding-large-vertical 0;
      background: $color-red;
      color: $white;

      .field-name-program-image-thumbnail {
        h3 {
          display: none;
        }
        float: left;
        margin-right: $padding-large-horizontal;
        img {
          max-height: 96px;
          width: auto;
          border: 1px solid $white;
          margin-bottom: 20px;
        }
      }

      .field-name-title {
        h1 {
          margin: 0;
          padding: 8px 0;
        }
      }
      .field-name-field-tagline {
        display: inline-block;
        padding-right: .2em;
        font-family: "warnockpro-regular";
        font-size: $font-size-base * 1.15;
        color: tint($color-red, 66%);
        &:after {
          content: " with";
        }
      }
      .field-name-field-broadcasters {
        display: inline-block;
        font-family: "warnockpro-regular";
        font-size: $font-size-base * 1.2;
        clear: both;
        margin-right: $padding-base-horizontal
      }
      .field-name-field-reference-genre {
        display: inline-block;
        font-family: "warnockpro-semiboldit";
        font-size: $font-size-small;
        padding-left: 16px;
        a {
          color: $brand-primary;
          &:hover, &:focus {
            color: tint($color-red, 66%);
          }
        }
        background: url('/sites/all/themes/custom/pbs/assets/images/pbs_dot_red_tint.png') no-repeat 0px 3px;
        background-size: 10px 10px;
      }
      .share-buttons {
        text-align: right;
        @include breakpoint-max(md) {
          text-align: left;
        }
      }
      .field-program-time {
        color: $white;
        margin-bottom: 4px;
      }
      .field-name-field-facebook-id,
      .field-name-field-twitter-id,
      .field-name-field-instagram-id {
        margin-top: 58px;
        @include breakpoint-max(md) {
          margin-top: 6px;
        }
        display: inline-block;
        img {
          width: 32px;
          height: 32px;
        }
        a {
          &:hover, &:focus {
            opacity: 0.75;
          }
        }
      }
      .field-name-field-twitter-id,
      .field-name-field-instagram-id
      {
        padding-left: 6px;
      }
    }

    .ds-section-main {
      .field-amrap-page {
        margin: ($padding-base-vertical * 2) 0;
      }
    }

    .ds-section-bottom {
      h3.label {
        position: relative;
        bottom: ($padding-large-vertical * 1.8);
      }
      background: $color-red;
      padding: ($padding-large-vertical * 2.4) 0 ($padding-large-vertical * 1.2) 0;
      .container {
        background: $white;
        padding-bottom: $padding-large-vertical;
      }
      .field-name-program-broadcasters {
        display: inline-block;
        font-family: "warnockpro-regular";
        font-size: $font-size-base * 1.10;
        margin-bottom: $padding-small-vertical;
        color: $gray-darker;
      }
    }
    .ds-section-related {
      .related-label {
        background: $brand-primary;
        padding: $padding-base-vertical;
      }
      h2 {
        @include heading_stripe();
      }

    }
    .program_info_bottom {
      height: 15px;
      background: $color-red;
    }
    .field-name-program-facebook,
    .field-name-program-twitter,
    .field-name-program-instagram {
      @include breakpoint-max(md) {
        margin-top: 6px;
      }
      display: inline-block;
      img {
        width: 16px;
        height: 16px;
      }
      a {
        &:hover, &:focus {
          opacity: 0.75;
        }
      }
    }
    .field-name-program-twitter,
    .field-name-program-instagram {
      padding-left: 6px;
    }
    .airnet-login {
      float: right;
      padding-bottom: 5px;
      font-size: $font-size-small;
      color: $gray;
      .ext {
        display: none;
      }
    }
  }

  &.view-mode-card {
    height: auto;
    padding-bottom: 64px;
    margin-bottom: $padding-base-vertical * 3;
    width: 100%;
    overflow: hidden;
    .col-sm-12 {
      padding: 0;
    }
    .field-program-image {
      padding-left: 15px;
      padding-right: 15px;
    }
    .group-program-info {
      @include angle(before);
      @include transition(all, 0.5s, ease);
      background: $color-red;
      height: 60px;
      width: 100%;
      position: absolute;
      top: 100%;
      height: 800px;
      padding-left: $padding-base-horizontal * 2;
      padding-right: $padding-base-horizontal * 2;
      color: $brand-primary;
      .field-title {
        h3 {
          font-size: $font-size-larger;
          color: $white;
          margin-top: $padding-base-vertical * 0.7;
          margin-bottom: $padding-base-vertical * 3;
        }
      }
    }
    .field-name-field-tagline {
      padding-right: .2em;
      font-family: "warnockpro-regular";
      font-size: $font-size-base * 1.15;
      color: tint($color-red, 66%);
      &:after {
        content: " with";
      }
    }
    .field-name-field-broadcasters {
      font-family: "warnockpro-regular";
      font-size: $font-size-base * 1.20;
      color: $white;
    }
    .field-name-field-reference-genre {
      font-family: "warnockpro-semiboldit";
      font-size: $font-size-small;
      a {
        color: tint($color-red, 66%);
      }
      background: url('/sites/all/themes/custom/pbs/assets/images/pbs_dot_red_tint.png') no-repeat 0px 3px;
      background-size: 10px 10px;
      padding-left: 16px;
      margin-left: 0;
    }
    .field-program-time {
      color: $white;
      margin-bottom: 4px;
      font-size: 0.9em;
    }
    &:hover, &:focus {
      .group-program-info {
        @include transition(all, 0.3s, ease);
        top: 65%;
        .field-title {
          h3 {
            @include transition(all, 0.5s, ease);
            margin-bottom: $padding-base-vertical * 1.2;
          }
        }
      }
    }
    @include breakpoint-max(md) {
      .group-program-info {
        top: 90%;
        .field-body {
          display: block;
          color: tint($color-red, 90);
          line-height: 1.1em;
        }
        .field-title {
          h3 {
            margin-bottom: $padding-base-vertical * 1;
          }
        }
      }
    }
  }
}
