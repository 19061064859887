// Styles for Instagram.
// -----------------------------------------------------------------------------

.block-instagram-block {
  .row {
    margin-left: -3px;
    margin-right: -3px;
    .col {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  img {

  }
}
