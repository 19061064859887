// Styles for fields.
// -----------------------------------------------------------------------------

a {
  &:hover, &:focus {
    text-decoration: none;
  }
}

.field-name-body,
.field-body {
  a {
    @include link_style();
  }
  a.btn {
    color: $white;
    border-bottom: 0;
    font-family: 'GT-Walsheim-Pro-Bold', Helvetica, Arial, sans-serif;
  }
}

.view-mode-card {
  .flag-outer {
    position: absolute;
    z-index: 1000;
    .flag-wrapper {
      padding: 0;
      a {
        padding: 0 5px;
        background: $brand-primary;
        color: $white;
        &:hover {
          background: $color-red;
        }
      }
    }
  }
  .flag-outer-newsletter {
    padding: 0 0 0 10px;
  }
  .flag-outer-front-page {
    right: 0;
    padding: 0 10px 0 0;
  }
}

.node-news {
  .flag-outer-newsletter {
    padding: 0 0 0 0px;
  }
}
