// Styles for Forms
// -----------------------------------------------------------------------------

.ui-multiselect {
  width: 100% !important;

  div.available, div.selected {
    width: 50% !important;
  }

  input.search {
    opacity: 1;
    height: 20px;
  }
}


div.form-type-radio {
  display: inline;
  margin: 10px;
}


// Bootstrap Drupal improvements.

.form-control.chosen-container-single {
  padding: 0;
  .chosen-single {
    height: unset;
    padding: 8px 15px;
    border: 0;
    background: none;
  }
}
