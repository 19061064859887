// Styles for CKeditor.
// -----------------------------------------------------------------------------

.cke_editable{
  //
  // Set images to responsive in wysiwyg.
  //
  img {
    @include responsive-image();
  }
  .row {
    margin: 0;
  }
}
.page-node-edit {
  #body-add-more-wrapper {
    margin: 0 -10px;
  }
}

.media-widget {
  a.browse {
    background-color: $brand-primary;
  }
}

